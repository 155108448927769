@import url("https://fonts.googleapis.com/css2?family=Mali:wght@200;300;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  color: inherit;
  display: inline-block;
}

img {
  width: 100% !important;
}

.App {
  overflow: hidden;
}

body {
  font-family: "Mali", cursive;
  background-color: black;
  color: white;
}

.btn-gradient {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(180deg, #0cf2a7 0%, #da22fc 100%);
}

.box-gradient-wrapper {
  background: linear-gradient(180deg, #0cf2a7 0%, #da22fc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
}

.box-gradient {
  /* width: calc(100% - 4px);
  height: calc(100% - 4px); */
  /* background: rgba(93, 61, 201, 0.12); */
  background-color: #0b0718;
  width: 100%;
  height: 100%;
}

.box-gradient-wrapper {
  background: linear-gradient(180deg, #0cf2a7 0%, #da22fc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
}

.box-gradient {
  /* width: calc(100% - 4px);
  height: calc(100% - 4px); */
  /* background: rgba(93, 61, 201, 0.12); */
  background-color: #0b0718;
  width: 100%;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(
    253deg,
    rgba(0, 255, 209, 0.8) 10.22%,
    rgba(235, 0, 255, 0.8) 113.34%
  );
}

.box-gradient-wrapper-2 {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
}

.box-gradient-2 {
  /* width: calc(100% - 4px);
  height: calc(100% - 4px); */
  /* background: rgba(93, 61, 201, 0.12); */
  background: linear-gradient(253deg, #00ffd1 10.22%, #eb00ff 113.34%);

  width: 100%;
  height: 100%;
}
